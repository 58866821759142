import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { lightTheme, darkTheme } from './theme';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import TokenDetail from './components/TokenDetail/TokenDetail';

function App() {
  const [runes, setRunes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [tokenInfo, setTokenInfo] = useState({});
  const [isDarkMode, setIsDarkMode] = useState(true); // State to toggle dark mode


  useEffect(() => {
    // Fetch runes data
    fetch('/data/rune.json')
      .then(res => res.json())
      .then(data => {
        setRunes(Object.values(data.runes)); // Assuming JSON structure has a "runes" object
        console.log("Runes loaded:", Object.values(data.runes));
      })
      .catch(error => {
        console.error("Failed to fetch runes data:", error);
      });

    // Fetch prices data
    fetch('/data/tableprice.json')
      .then(res => res.json())
      .then(data => {
        setPrices(data);
        console.log("Prices loaded:", data);
      })
      .catch(error => {
        console.error("Failed to fetch prices data:", error);
      });

    // Fetch token information
    fetch('/data/TokenInfo.json')
      .then(res => res.json())
      .then(data => {
        setTokenInfo(data);
        console.log("Token information loaded:", data);
      })  
      .catch(error => {
        console.error("Failed to fetch token information:", error);
      });
  }, []);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <CssBaseline /> {/* Ensures consistent background and text colors */}
      <Router basename="/">
        <div className="App">
          {/* You can place Header and Footer outside Routes if they are common across all pages */}
          <Header isDarkMode={isDarkMode} setIsDarkMode={setIsDarkMode} />
          <Routes>
            <Route path="/" element={<Home tokens={runes} prices={prices} />} />
            <Route path="/token/:number" element={<TokenDetail tokens={runes} tokenInfo={tokenInfo} />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;













































/*
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TokenDetail from './components/TokenDetail/TokenDetail';

function App() {
  const [runes, setRunes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [tokenInfo, setTokenInfo] = useState({});

  useEffect(() => {
    // Fetch runes data
    fetch('/data/rune.json')
      .then(res => res.json())
      .then(data => {
        setRunes(Object.values(data.runes)); // Assuming JSON structure has a "runes" object
        console.log("Runes loaded:", Object.values(data.runes));
      })
      .catch(error => {
        console.error("Failed to fetch runes data:", error);
      });

    // Fetch prices data
    fetch('/data/tableprice.json')
      .then(res => res.json())
      .then(data => {
        setPrices(data);
        console.log("Prices loaded:", data);
      })
      .catch(error => {
        console.error("Failed to fetch prices data:", error);
      });

    // Fetch token information
    fetch('/data/TokenInfo.json')
      .then(res => res.json())
      .then(data => {
        setTokenInfo(data);
        console.log("Token information loaded:", data);
      })  
      .catch(error => {
        console.error("Failed to fetch token information:", error);
      });
  }, []);

  return (
    <Router basename="/">
      <div className="App">
        <Routes>
          <Route path="/" element={<Home tokens={runes} prices={prices} />} />
          <Route path="/token/:number" element={<TokenDetail tokens={runes} tokenInfo={tokenInfo} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
*/









































/*
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TokenDetail from './components/TokenDetail/TokenDetail';

function App() {
  const [runes, setRunes] = useState([]);
  const [prices, setPrices] = useState([]);
  const [tokenInfo, setTokenInfo] = useState({});

  useEffect(() => {
    // Fetch runes data
    fetch('/data/rune.json')
      .then(res => res.json())
      .then(data => setRunes(Object.values(data.runes))); // Assuming JSON structure has a "runes" object

    // Fetch prices data
    fetch('/data/tableprice.json')
      .then(res => res.json())
      .then(setPrices);

    // Fetch token information
    fetch('/data/TokenInfo.json')
      .then(res => res.json())
      .then(setTokenInfo)  

  }, []);

  return (
    <Router basename="/">
      <div className="App">
        <Routes>
          <Route path="/" element={<Home tokens={runes} prices={prices} />} />
          <Route path="/token/:rune" element={<TokenDetail tokens={runes} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

*/


































/*
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TokenDetail from './components/TokenDetail/TokenDetail';
import data from './data/rune.json';
import prices from './data/tableprice.json';

function App() {
  const runes = data.runes;
  const tokensArray = Object.keys(runes).map(key => runes[key]);

  return (
    <Router basename="/">
      <div className="App">
        <Routes>
          <Route path="/" element={<Home tokens={tokensArray} prices={prices} />} />
          <Route path="/token/:rune" element={<TokenDetail tokens={tokensArray} />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;

*/



































/*
// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import TokenDetail from './components/TokenDetail/TokenDetail';
import data from './data/rune.json';
import prices from './data/tableprice.json';

function App() {
  const runes = data.runes;
  const tokensArray = Object.keys(runes).map(key => runes[key]);

  return (
    <Router basename="/">
      <div className="App">
        <Routes>
          <Route path="/" element={<Home tokens={tokensArray} prices={prices} />} />
          <Route path="/token/:rune" element={<TokenDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
*/






























/*
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import TokenTable from './components/TokenTable/TokenTable';
import TokenDetail from './components/TokenDetail/TokenDetail'; // Make sure to import the detail component
import data from './data/rune.json';  // Importing the entire JSON file
import prices from './data/tableprice.json';

function App() {
  const runes = data.runes; // Accessing the 'runes' property from the imported JSON
  const tokensArray = Object.keys(runes).map(key => runes[key]); // Convert object to array

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<TokenTable tokens={tokensArray} prices={prices} />} />
          <Route path="/token/:id" element={<TokenDetail />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
*/
















/*
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import TokenTable from './components/TokenTable';
import data from './data/rune.json'; // Importing the entire JSON file
import prices from './data/tableprice.json';

function App() {
  const runes = data.runes; // Accessing the 'runes' property from the imported JSON
  const tokensArray = Object.keys(runes).map(key => runes[key]); // Convert object to array
  return (
    <div className="App">
      <TokenTable tokens={tokensArray} prices={prices}/>
    </div>
  );
}

export default App;
*/