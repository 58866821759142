// src/components/common/Footer.js
import React from 'react';
import { Box, Typography, IconButton, Link } from '@mui/material';
import XIcon from '@mui/icons-material/X';
const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
        borderTop: '1px solid #ccc',
        marginTop: 'auto'
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src="/NEXUSLOGO.png" alt="Nexus Logo" style={{ height: 50 }} />
        <Typography variant="body2" sx={{ marginLeft: 2 }}>
          Copyright © NEXUS {year}
        </Typography>
      </Box>
      <Box>
        <IconButton component={Link} href="https://twitter.com/nexus" target="_blank">
          <XIcon />
        </IconButton>
        <IconButton component="a" href="https://discord.gg" target="_blank">
          <img src="/Discord.png" alt="Discord" style={{ width: 24, height: 24 }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Footer;

