// src/components/Header.js
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Menu, MenuItem, Switch } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

const Header = ({ isDarkMode, setIsDarkMode }) => {
  const theme = useTheme();
  const textColor = theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900];

  const [navbarData, setNavbarData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetch('/data/navbardata.json')
      .then(response => response.json())
      .then(data => setNavbarData(data));
  }, []);

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MarketCapIndicator = ({ value }) => {
    const isPositive = value.includes('+');
    const Icon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;
    const color = isPositive ? 'green' : 'red';
    return (
      <Typography component="span" style={{ fontWeight: 'bold', color }}>
        <Icon style={{ verticalAlign: 'middle' }} fontSize="small" /> {value}
      </Typography>
    );
  };


  

  return (
    <AppBar position="static" color="default" style={{ backgroundColor: theme.palette.background.paper }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="logo" size="large">
          <img src="/NEXUSLOGO.png" alt="Logo" style={{ width: 45, height: 45 }} />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: textColor }}>
          {/* Using Box to wrap each segment and applying margins */}
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Total Runes: {navbarData.totalRunes}</Typography></Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Exchanges: {navbarData.exchanges}</Typography></Box>
          <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ color: textColor }}>
              Market Cap: {navbarData.marketCap}
              {navbarData.marketCapChange && <MarketCapIndicator value={navbarData.marketCapChange} />}
            </Typography>
          </Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>24h Vol: {navbarData.volume24h}</Typography></Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Dominance: BTC {navbarData.btcDominance}</Typography></Box>
          <Box><Typography variant="caption" sx={{ color: textColor }}>Gas: {navbarData.gasPrice}</Typography></Box>
        </Box>
        <IconButton color="inherit" onClick={handleMenu}>
          <SettingsIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClose}>Language</MenuItem>
          <MenuItem onClick={handleClose}>Currency</MenuItem>
          <MenuItem onClick={handleClose}>
            Dark Mode <Switch checked={isDarkMode} onChange={handleDarkModeToggle} />
          </MenuItem>
        </Menu>
        <Button color="inherit">Login</Button>
        <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
      </Toolbar>
    </AppBar>
  );
};


export default Header;










































/*
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Menu, MenuItem, Switch } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material';

const Header = ({ isDarkMode, setIsDarkMode }) => {
  const theme = useTheme();
  const textColor = theme.palette.mode === 'dark' ? theme.palette.grey[50] : theme.palette.grey[900];

  const [navbarData, setNavbarData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    fetch('/data/navbardata.json')
      .then(response => response.json())
      .then(data => setNavbarData(data));
  }, []);

  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MarketCapIndicator = ({ value }) => {
    const isPositive = value.includes('+');
    const Icon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;
    const color = isPositive ? 'green' : 'red';
    return (
      <Typography component="span" style={{ fontWeight: 'bold', color }}>
        <Icon style={{ verticalAlign: 'middle' }} fontSize="small" /> {value}
      </Typography>
    );
  };


  

  return (
    <AppBar position="static" color="default" style={{ backgroundColor: theme.palette.background.paper }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="logo" size="large">
          <img src="/NEXUSLOGO.png" alt="Logo" style={{ width: 45, height: 45 }} />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', color: textColor }}>
          {/* Using Box to wrap each segment and applying margins 
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Total Runes: {navbarData.totalRunes}</Typography></Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Exchanges: {navbarData.exchanges}</Typography></Box>
          <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption" sx={{ color: textColor }}>
              Market Cap: {navbarData.marketCap}
              {navbarData.marketCapChange && <MarketCapIndicator value={navbarData.marketCapChange} />}
            </Typography>
          </Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>24h Vol: {navbarData.volume24h}</Typography></Box>
          <Box mr={2}><Typography variant="caption" sx={{ color: textColor }}>Dominance: BTC {navbarData.btcDominance}</Typography></Box>
          <Box><Typography variant="caption" sx={{ color: textColor }}>Gas: {navbarData.gasPrice}</Typography></Box>
        </Box>
        <IconButton color="inherit" onClick={handleMenu}>
          <SettingsIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClose}>Language</MenuItem>
          <MenuItem onClick={handleClose}>Currency</MenuItem>
          <MenuItem onClick={handleClose}>
            Dark Mode <Switch checked={isDarkMode} onChange={handleDarkModeToggle} />
          </MenuItem>
        </Menu>
        <Button color="inherit">Login</Button>
        <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
      </Toolbar>
    </AppBar>
  );
};


export default Header;


*/




























/* before dark mode for header component applying theme teext color
import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Box, Menu, MenuItem, Switch } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';

const Header = ({isDarkMode, setIsDarkMode}) => {
  const [navbarData, setNavbarData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null); // For dropdown menu
  const open = Boolean(anchorEl);
  const handleDarkModeToggle = () => {
    setIsDarkMode(!isDarkMode); // Toggle dark mode state
  };
  useEffect(() => {
    fetch('/data/navbardata.json')
      .then(response => response.json())
      .then(data => setNavbarData(data));
  }, []);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const MarketCapIndicator = ({ value }) => {
    const isPositive = value.includes('+');
    const Icon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;
    const color = isPositive ? 'green' : 'red';
    return (
      <Typography component="span" style={{ fontWeight: 'bold', color: color }}>
        <Icon style={{ verticalAlign: 'middle' }} fontSize="small" /> {value}
      </Typography>
    );
  };

  return (
    <AppBar position="static" color="default" style={{ color: 'black' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="logo" size="large">
          <img src="/NEXUSLOGO.png" alt="Logo" style={{ width: 45, height: 45 }} />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {/* Using Box to wrap each segment and applying margins 
          <Box mr={2}><Typography variant="caption">Total Runes: {navbarData.totalRunes}</Typography></Box>
          <Box mr={2}><Typography variant="caption">Exchanges: {navbarData.exchanges}</Typography></Box>
          <Box mr={2} sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="caption">
              Market Cap: {navbarData.marketCap}
              {navbarData.marketCapChange && <MarketCapIndicator value={navbarData.marketCapChange} />}
            </Typography>
          </Box>
          <Box mr={2}><Typography variant="caption">24h Vol: {navbarData.volume24h}</Typography></Box>
          <Box mr={2}><Typography variant="caption">Dominance: BTC {navbarData.btcDominance}</Typography></Box>
          <Box><Typography variant="caption">Gas: {navbarData.gasPrice}</Typography></Box>
        </Box>
        <IconButton color="inherit" onClick={handleMenu}>
          <SettingsIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={handleClose}>Language</MenuItem>
          <MenuItem onClick={handleClose}>Currency</MenuItem>
          <MenuItem onClick={handleClose}>
            Dark Mode <Switch checked={isDarkMode} onChange={handleDarkModeToggle} />
          </MenuItem>
        </Menu>
        <Button color="inherit">Login</Button>
        <Button color="inherit" component={Link} to="/signup">Sign Up</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
*/