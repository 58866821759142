// src/components/TokenInfo/TokenInfo.js

import React from 'react';
import { Typography, Box, Paper,Avatar, LinearProgress } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { 
  formatNumber,
  calculateTotalSupply,
  timeSince,
  abbreviateNumber,
  formatWithDivisibility
 } from '../../utils/formatHelpers';



const TokenInfo = ({ token }) => {
  const theme = useTheme();
  //add formatHelpers.js from //src/utils/formatHelpers.js
  const totalSupply = calculateTotalSupply(token);
  const mintProgress = token.mints / totalSupply * 100;
  
  
// Styles that change with the theme
const paperStyle = {
  padding: 16,
  marginRight: 16,
  backgroundColor: theme.palette.background.paper, // Ensures the background color matches the theme
  color: theme.palette.text.primary, // Ensures the text color matches the theme
};



  return (
    <Box>
    <Paper style={paperStyle}>
      
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={`https://ordinals.com/content/${token.etching}i0`} alt={token.rune} sx={{ marginRight: 1 }} />
            <span>{token.rune} {token.symbol}</span>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              
              paddingTop: 1,
              paddingBottom: 1,
              marginBottom: 1
          }}>
        <Typography variant="h5">On-Chain Info:</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Rune Number:</Typography>
        <Typography variant="body2" >{token.number}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Created:</Typography>
        <Typography variant="body2" >{timeSince(token.timestamp)}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Minted:</Typography>
        <Typography variant="body2" >{formatNumber(token.mints)}</Typography>
        <LinearProgress variant="determinate" value={mintProgress} sx={{ marginTop: 1, width: '50%' }} />
        <Typography variant="caption" >{`${Math.round(mintProgress)}%`}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Mint Cap:</Typography>
        <Typography variant="body2" >{formatNumber(token.terms?.cap, true)}</Typography>
        
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Premine:</Typography>
        <Typography variant="body2" >{formatWithDivisibility(token.premine, token.divisibility)}</Typography>
      </Box>

      
      
      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Circulating Supply:</Typography>
        <Typography variant="body2" >{abbreviateNumber(formatWithDivisibility(token.supply, token.divisibility))}</Typography>
      </Box>
      
      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Total Supply:</Typography>
        <Typography variant="body2" >{abbreviateNumber(totalSupply)}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Burned:</Typography>
        <Typography variant="body2" >{abbreviateNumber(token.burned)}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="caption" >Block:</Typography>
        <Typography variant="body2" >{token.block}</Typography>
      </Box>

    </Paper>














    {/* New Paper for Market Details */}
    <Paper style={paperStyle}>
      <Box>
        <Typography variant="h5">Market Details:</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">Market Cap: {}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">Volume: {}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">Current Price: {}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">24h High: {}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">24h Low: {}</Typography>
      </Box>
    </Paper>









    {/* New Paper for comunity details */}
    <Paper style={paperStyle}>
      <Box>
        <Typography variant="h5">Community Info:</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingTop: 1,
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">Socials: {}</Typography>
      </Box>

      <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',  // Ensures label and value are on opposite ends
              borderBottom: '1px solid #e0e0e0',
              paddingBottom: 1,
              marginBottom: 2
          }}>
        <Typography variant="body2">Website: {}</Typography>
      </Box>

    </Paper>




  </Box>
  );
};

export default TokenInfo;


/*

      <Box sx={{ borderBottom: '1px solid #e0e0e0', paddingBottom: 1, marginBottom: 2 }}>
        <Typography variant="caption" sx={{ color: '#9e9e9e' }}>Rune Number:</Typography>
        <Typography variant="body2" sx={{ color: '#212121' }}>{token.number}</Typography>
      </Box>

*/




/*

return (
    <Paper style={{ padding: 16, marginRight: 16 }}>
      
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={`https://ordinals.com/content/${token.etching}i0`} alt={token.rune} sx={{ marginRight: 1 }} />
            <span>{token.rune} {token.symbol}</span>
      </Box>

      <Box>
        <Typography variant="h5">on-chain info:</Typography>
      </Box>

      <Box>
        <Typography variant="body2">Rune Number: {token.number}</Typography>
      </Box>

      <Box>
        <Typography variant="body2">Created: {timeSince(token.timestamp)}</Typography>
      </Box>

      <Box>
        <Typography variant="body2">Minted: {formatNumber(token.mints)}</Typography>
        {/*i want to add a small progress bar 
        <LinearProgress variant="determinate" value={mintProgress} />
        
        <LinearProgress variant="determinate" value={mintProgress} />
      </Box>

      <Box>
        <Typography variant="body2">Mint Cap: {formatNumber(token.terms?.cap, true)}</Typography>
      </Box>

      <Box>
        <Typography variant="body2">Premine: {formatWithDivisibility(token.premine, token.divisibility)}</Typography>
      </Box>
      
      <Box>
        <Typography variant="caption">Circulating Supply:</Typography>
        <Typography variant="body2">{formatWithDivisibility(token.supply, token.divisibility)}</Typography>
      </Box>

      <Typography variant="body2">Total Supply: {abbreviateNumber(totalSupply)}</Typography>
      
      <Box>       
        <Typography variant="body2">Burned: {abbreviateNumber(token.burned)}</Typography>
      </Box>

      <Box>
        <Typography variant="body2">Block: {token.block}</Typography>
      </Box>
        
    </Paper>
  );
};


*/













/*
import React from 'react';
import { Typography, Box, Paper } from '@mui/material';

const TokenInfo = ({ token }) => {
  return (
    <Paper style={{ padding: 16, marginRight: 16 }}>
      <Typography variant="h4">{token.rune}</Typography>
      <Typography variant="subtitle1">Market Cap: {token.marketCap}</Typography>
      <Typography variant="body1">Total Supply: {token.supply}</Typography>
      <Typography variant="body2">Block: {token.block}</Typography>
      <Typography variant="body2">Mints: {token.mints}</Typography>
      {/* More fields can be added here 
    </Paper>
  );
};

export default TokenInfo;
*/