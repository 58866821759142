import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, TablePagination, Paper, Avatar, Box, TextField } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Typography } from '@mui/material';
import TokenChart from './TokenChart';
import chartStyles from './TokenChart.module.css';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import tableStyles from './TokenTable.module.css';
import { useNavigate } from 'react-router-dom';

const TokenTable = ({ tokens, prices }) => {
    const navigate = useNavigate();
    /*
    const handleRowClick = (token) => {
      const tokenName = token.rune.replace(/\s+/g, '-').toLowerCase();  // Slugify the token name if necessary
      navigate(`/token/${tokenName}`); // Use token name for navigation
    };
    */
   

    
    const handleRowClick = (token) => {
      navigate(`/token/${token.number}`); // Use the 'number' field as the URL parameter
    };
    

    /*
    const handleRowClick = (token) => {
      navigate(`/token/${token.key}`); // Assuming 'key' is the field you want to use
    };
    */
    

  const [orderDirection, setOrderDirection] = useState('asc');
  const [orderBy, setOrderBy] = useState('number');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");

  // Create a map for quick lookup of prices
  const priceMap = prices.reduce((acc, price) => {
    acc[price.id] = price;
    return acc;
  }, {});

  const handleRequestSort = (property) => {
    const isAscending = orderBy === property && orderDirection === 'asc';
    setOrderDirection(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };
  
  const handleSearchChange = (event) => {
    setSearchText(event.target.value.toLowerCase());
  };

  const filteredTokens = tokens.filter(token => token.rune.toLowerCase().includes(searchText));
  
  const sortedRows = () => {
    const sortedTokens = [...filteredTokens].sort((a, b) => {
      let valueA, valueB;
  
      if (['price', '1h', '24h', '7d', '24hVolume', 'marketCap'].includes(orderBy)) {
        const priceDataA = priceMap[a.id] || {};
        const priceDataB = priceMap[b.id] || {};
        valueA = Number(priceDataA[orderBy]);
        valueB = Number(priceDataB[orderBy]);
      } else if (orderBy === 'rune') {
        valueA = a.rune.toLowerCase(); // Note: already lower case from filter, but reiterated for safety
        valueB = b.rune.toLowerCase();
      } else {
        valueA = a[orderBy];
        valueB = b[orderBy];
      }
  
      return valueA < valueB ? (orderDirection === 'asc' ? -1 : 1) : valueA > valueB ? (orderDirection === 'asc' ? 1 : -1) : 0;
    });
  
    return sortedTokens.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  };


  //Percentage Display Component
  const PercentDisplay = ({ value }) => {
    const isPositive = value >= 0;
    const color = isPositive ? 'green' : 'red';
    const Icon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;
  
    return (
      <Typography color={color} component="span">
        {Icon && <Icon fontSize="small" style={{ verticalAlign: 'middle' }} />}
        {`${Math.abs(value)}%`}
      </Typography>
    );
  };

  // Currency Formatting Function
  const formatCurrency = (amount) => {
    return `$${Number(amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  };
  

  return (
    <TableContainer component={Paper}>
        <div className={tableStyles.searchInputContainer}>
  <TextField
    label="Search Runes"
    variant="outlined"
    value={searchText}
    onChange={handleSearchChange}
    fullWidth
    className={tableStyles.searchInput}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
</div>
      <Table aria-label="rune tokens table">
        <TableHead>
          <TableRow>
            {["Number", "Rune", "Price", "1h", "24h", "7d", "24h Volume", "Market Cap"].map((header) => (
              <TableCell key={header}>
                <TableSortLabel
                  active={orderBy === header.toLowerCase()}
                  direction={orderBy === header.toLowerCase() ? orderDirection : 'asc'}
                  onClick={() => handleRequestSort(header.toLowerCase())}
                >
                  {header}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell>Last 7 Days</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
  {sortedRows().map((token) => {
    const priceData = prices.find(price => price.id === token.id) || {};
    const last7DaysData = priceData.last7Days || [];  // Assuming `last7Days` is an array of 7 data points.

    return (
      <TableRow 
        key={token.id} 
        onClick={() => handleRowClick(token)} 
        style={{ cursor: 'pointer' }}
      >
        <TableCell>{token.number}</TableCell>
        <TableCell>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar src={`https://ordinals.com/content/${token.etching}i0`} alt={token.rune} sx={{ marginRight: 1 }} />
            <span>{token.rune} {token.symbol}</span>
          </Box>
        </TableCell>
        <TableCell>{priceData.price ? formatCurrency(priceData.price) : '-'}</TableCell>
        <TableCell><PercentDisplay value={priceData['1h']} /></TableCell>
        <TableCell><PercentDisplay value={priceData['24h']} /></TableCell>
        <TableCell><PercentDisplay value={priceData['7d']} /></TableCell>
        <TableCell>{priceData['24hVolume'] ? formatCurrency(priceData['24hVolume']) : '-'}</TableCell>
        <TableCell>{priceData['marketCap'] ? formatCurrency(priceData['marketCap']) : '-'}</TableCell>
        <TableCell>
          <div className={chartStyles.chartCell}>
            <TokenChart data={last7DaysData} />
          </div>
        </TableCell>
      </TableRow>
    );
  })}
</TableBody>

        <TablePagination
          
          count={tokens.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          onRowsPerPageChange={(event) => setRowsPerPage(parseInt(event.target.value, 10))}
        />
      </Table>
    </TableContainer>
  );
};

export default TokenTable;