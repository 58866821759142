// src/components/TokenCharts/TokenCharts.js


import React from 'react';
import { Paper } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';



const TokenCharts = ({ token }) => {
  // Sample data
  const data = [
    { name: 'Day 1', price: 240 },
    { name: 'Day 2', price: 139 },
    { name: 'Day 3', price: 980 },
    { name: 'Day 4', price: 390 },
    { name: 'Day 6', price: 480 },
    { name: 'Day 7', price: 500 },
    { name: 'Day 8', price: 300 },
    { name: 'Day 9', price: 800 },
    { name: 'Day 10', price: 900 },
  ];

  return (
    <Paper style={{ padding: 16, height: 300 }}>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="price" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default TokenCharts;
