// src/pages/Home.js
import React from 'react';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import TokenTable from '../components/TokenTable/TokenTable';
import { Container } from '@mui/material';

const Home = ({ tokens, prices }) => {
  return (
    <div>
      
      <Container maxWidth="lg" style={{ marginTop: '20px', marginBottom: '20px' }}>
        <TokenTable tokens={tokens} prices={prices} />
      </Container>
      
    </div>
  );
};

export default Home;
