// src/components/TokenDetail/TokenDetail.js

// src/components/TokenDetail/TokenDetail.js
import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Container } from '@mui/material';
import TokenInfo from '../TokenInfo/TokenInfo';
import TokenCharts from '../TokenCharts/TokenCharts';
import Header from '../common/Header';
import Footer from '../common/Footer';


const TokenDetail = ({ tokens }) => {
  const { number } = useParams();
  const token = tokens.find(t => t.number.toString() === number);





  if (!token) {
    return (
      <div>

        <p>Token not found in tokendetails.js</p>

      </div>
    );
  }

  return (
    <div>

      <Container maxWidth="lg" style={{ marginTop: '30px', marginBottom: '20px' }}>
        <Grid container spacing={1} justifyContent="center" alignItems="start">
          <Grid item xs={12} md={4}>
            <TokenInfo token={token} />
          </Grid>
          <Grid item xs={12} md={7}>
            <TokenCharts token={token} />
          </Grid>
        </Grid>
      </Container>

    </div>
  );
};

export default TokenDetail;


















































/*

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Box } from '@mui/material';
import TokenInfo from '../TokenInfo/TokenInfo';
import TokenCharts from '../TokenCharts/TokenCharts';
import Header from '../common/Header'; // Adjust path as necessary
import Footer from '../common/Footer'; // Adjust path as necessary

const TokenDetail = ({ tokens }) => {
  const { number } = useParams(); // Correctly using 'number' from the URL
  const token = tokens.find(t => t.number.toString() === number);

  if (!token) {
    return (
      <div>
        <Header />
        <p>Token not found tokendetails.js</p>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <Box 
        display="flex" 
        flexDirection={{ xs: 'column', md: 'row' }} // Stack vertically on small screens, horizontally on medium and larger
        justifyContent="center" 
        alignItems="center" 
        minHeight="40vh" 
        p={1}
      >
        <Box flex={1} maxWidth="30%">
          <TokenInfo token={token} />
        </Box>
        <Box flex={1} maxWidth="70%">
          <TokenCharts token={token} />
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default TokenDetail;


*/
















































































/*  runekey and number from url

const TokenDetail = ({ tokens }) => {
  
  const { runekey } = useParams();
  console.log('Rune Key from URL:', runekey);
  console.log('Available Tokens:', tokens.map(t => t.rune.replace(/\s+/g, '-')));
  const { number } = useParams(); // Get the number from URL
  const token = tokens.find(t => t.number.toString() === number); // Find the token by number, ensuring both are strings for comparison
  console.log('Found Token:', token);

  if (!token) {
    return (
      <div>
        <Header />
        <p>Token not found tokendetails.js</p>
        <Footer />
      </div>
    );
  }


*/