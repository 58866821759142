import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto';
import styles from './TokenChart.module.css';

const TokenChart = ({ data }) => {
  const lineColor = data[data.length - 1] >= data[data.length - 2] ? 'rgb(0, 128, 0)' : 'rgb(255, 0, 0)';

  const chartData = {
    labels: Array.from({ length: data.length }, (_, i) => ''),
    datasets: [
      {
        label: '',
        data: data,
        borderColor: lineColor,
        borderWidth: 2,
        pointRadius: 0,
      }
    ],
  };

  const options = {
    scales: {
      x: {
        display: false, // Hides X-axis labels
        grid: {
          display: false // Hides grid lines
        }
      },
      y: {
        display: false, // Hides Y-axis labels
        grid: {
          display: false // Hides grid lines
        }
      }
    },
    plugins: {
      legend: {
        display: false // Hides legend
      }
    },
    elements: {
      line: {
        tension: 0.3 // Makes the line slightly curved, set to 0 for straight lines
      }
    },
    maintainAspectRatio: false,
    responsive: true
  };

  return (
    <div className={styles.chartContainer}>
      <Line data={chartData} options={options} />
    </div>
  );
};


export default TokenChart;
