// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6', // Example color
    },
    secondary: {
      main: '#19857b', // Example color
    },
    error: {
      main: '#f44336',
    },
    background: {
      default: '#fff',
    },
  },
  typography: {
    fontFamily: 'Arial, sans-serif',
    // You can add more customization here
  },
  // You can add other global styles and overrides
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    // Define other properties if necessary
  },
});

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    // Define other properties if necessary
  },
});

export default theme;
