// src/utils/formatHelpers.js
export function formatNumber(number, isCap = false) {
    if (number === undefined || number === null) {
      return 'N/A';
    }
    if (isCap && number === 0) {
      return 'Unlimited';
    }
    return number.toLocaleString();
  }
  
  export function calculateTotalSupply(coin) {
    const divisibilityFactor = Math.pow(10, coin.divisibility || 0);
    const amount = coin.terms?.amount || 0;
    let cap = coin.terms?.cap;
  
    if (cap === 0) {
      cap = Infinity;
    }
  
    const premine = coin.premine || 0;
    let totalSupply = (amount * cap) + premine;
  
    if (totalSupply === Infinity) {
      totalSupply = premine;
    }
  
    return totalSupply / divisibilityFactor;
  }
  
  export function timeSince(date) {
    const seconds = Math.floor((new Date() - new Date(date)) / 1000);
    let interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }
  
  export function abbreviateNumber(value) {
    let newValue = value;
    if (value >= 1000) {
      const suffixes = ["", "K", "M", "B", "T", "P", "E"];
      let suffixNum = 0;
      while (newValue >= 1000) {
        newValue /= 1000;
        suffixNum++;
      }
      newValue = newValue.toPrecision(3);
      newValue += suffixes[suffixNum];
    }
    return newValue;
  }
  
  // Format with divisibility
  export function formatWithDivisibility(value, divisibility) {
    if (value === undefined || value === null) {
      return 'N/A';
    }
  
    // Apply divisibility factor
    const divisibilityFactor = Math.pow(10, divisibility || 0);
    const adjustedValue = value / divisibilityFactor;
  
    // Abbreviate the adjusted number
    return abbreviateNumber(adjustedValue);
  }